@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import "views/login";
@import "views/users-client";
@import "views/menu-app";
@import "views/users";
@import "views/managerial";
@import "views/reviews-client";
@import "views/ranking-client";
@import "views/planning.scss";
@import "views/load-client.scss";
