.container-managerial{
  font-family: "Montserrat", sans-serif !important;
  .ant-card{
    background-color: #F0F2F5;
    font-family: "Montserrat", sans-serif !important;
    border-radius: 6px;
    .ant-card-head{
      border-bottom: 1px solid #E0E0E0;
    }
    .ant-btn {
      background-color: #F0F2F5;

    }
   }
  
  .ant-radio-button-checked{
    background: #F6F8FA;
    border-radius: 6px;
    font-family: "Montserrat", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    height: 35px;
  }
 
  #pieChart{
    .recharts-legend-wrapper{
      bottom: 125px !important;
    }
   
  }
  #pieChart2{
    .recharts-legend-wrapper{
      bottom: 125px !important;
    }
   
  }
  #tableCash{
    .ant-card-body{
      padding: 0px !important;
    }
  }
  .recharts-legend-wrapper{
    bottom: -20px !important;
  }
 
  .card-font-title{
    font-size: 16px;
    font-weight: 600;
    color: #090D0F;

  }
  .card-font-title-accounting{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    text-align: center;

  }
  .card-font-title-cash{
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    text-align: center;

  }
  .card-font-body-green{
    font-size: 30px;
    font-weight: bold;
    color: #2A8DEB;
    padding: 10px;

    box-shadow: #00000020 0px 14px 16px

  }
  .card-font-body-green-cash{
    font-size: 30px;
    font-weight: bold;
    color: #2A8DEB;
    padding: 30px;
    text-align: center;

    box-shadow: #00000020 0px 14px 16px

  }
  .card-font-body-red{
    font-size: 30px;
    font-weight: bold;
    padding: 10px;

    color: #ED5E80;
    box-shadow: #00000020 0px 14px 16px

  }
  .card-font-body-default{
    font-size: 30px;
    font-weight: bold;
    padding: 10px;

    color: #fff;
    box-shadow: #00000020 0px 14px 16px

  }
  .card-green{
    background-color: #B6D7A8;
    height: 30vh;
  }
  .card-grey{
    background-color: #323A47;
    min-height: 300px;
    box-shadow: #00000020 0px 14px 16px

  }
  .card-grey-mini{
    background-color: #323A47;
    height: 40vh;
    box-shadow: #00000020 0px 14px 16px

  }
  .card-default{
    font-family: "Montserrat", sans-serif !important;
    background-color: #F0F2F5;
  }
  .card-default-mini{
    height: 40vh;
    box-shadow: #00000020 0px 14px 16px

  }

  .card-default-mini-chart{
    box-shadow: #00000020 0px 14px 16px;  
    margin-top: 30px;
  }
  .card-default-chart{
    min-height: 600px;
    box-shadow: #00000020 0px 14px 16px;
    margin-top: 30px;



  }
  
  .card-accouting{
    text-align: center;
    margin-top: 10px;
    .ant-table-row{
      background: #F0F2F5;
      font-family: "Montserrat", sans-serif !important;
      font-weight: 500;
    }
    .ant-table-small{
      font-size: 10px;

    }
    .ant-pagination {
      font-size: 8px;

    }
   
    
  }
  .card-accouting .ant-table-tbody > tr > td {
    border-bottom: 1px solid #E0E0E0 !important; /* Cor e espessura da borda */
  }

  .card-accouting .ant-table-thead > tr > th {
    border-bottom: 1px solid #E0E0E0 !important; /* Cor e espessura da borda */
  }
  .card-accouting-mini{
    text-align: center;
    box-shadow: #00000020 0px 14px 16px;
    margin-top: 30px;
  

  }
  .card-red{
    background-color: #ED5E80
    
  }
  
}

.welcome{
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  padding: 30px;
  box-shadow: #00000020 0px 14px 16px;
  background-color: #323A47;
  border-radius: 6px;
}
.annotation{
  color:#090D0F !important
}